import axios from "axios";
import { BASE_URL, PAYMENT_BASE_URL } from "../utils/constants";

export const savePayment = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/payment/AddPayment`, payload, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPaymentInformation = async (barcodeId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/case/GetPayInfoByBarcodeId/${barcodeId}`,
      {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPaymentInformationByGroupId = async (groupId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/case/GetCaseListInfoByQuoteGroupId/${groupId}`,
      {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const generateUid = async (payload) => {
  try {
    const response = await axios.post(`${PAYMENT_BASE_URL}/payment/GeneratePaymentUid`, payload, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY_PAYMENT,
        "System": process.env.REACT_APP_TTC_SYSTEM
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};