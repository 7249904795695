import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  IconButton,
  Checkbox,
  Collapse,
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Backdrop,
  FormControlLabel,
} from "@material-ui/core";
import { withRouter } from "react-router";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import {
  getPaymentsCasesList,
  addPaymentsProcessed,
  getProcessedPayments,
} from "../api/client";
import NewQuotePage from "./NewQuotePage";
import { validateOtp } from "../api/otp";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 500,
    width: 200,
  },
  root: {
    flexGrow: 1,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  button: {
    color: "#FFEF65",
    backgroundColor: "black",
    height: 50,
    width: "50%",
    fontWeight: "bold",
    fontSize: 16,
  },
  inputText: {
    width: "100%",
    height: 30,
    marginTop: 20,
  },
});

class QuoteListPage extends React.Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    this.state = {
      userid: params.get("userid"),
      token: params.get("otptoken"),
      open: false,
      isSelected: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      showMessage: false,
      list: [],
      processedList: [],
      notProcessedList: [],
      openAlert: false,
      messageAlert: "",
      backShow: false,
      showProcessed: false,
      showPage: false,
    };
    this.setState = this.setState.bind(this);
    this.handleClose = this.handleClose.bind(this);
    //this.handleProcessPayments = this.handleProcessPayments.bind(this);
    this.handleShowProcessed = this.handleShowProcessed.bind(this);
    //this.checkRow = this.checkRow.bind(this);
  }

  componentDidMount() {
    this.validateOtp();
  }

  async validateOtp() {
    const response = await validateOtp({
      userName: this.state.userid,
      token: this.state.token,
    });
    if (typeof response == "undefined") {
      this.props.history.push("/unauthorized");
    } else {
      this.setState({ showPage: true });
    }
  }

  async paymentsList() {
    try {
      this.setState({ backShow: true, showProcessed: false });
      var start = new Date(this.state.startDate);
      var end = new Date(this.state.endDate);
      if (start > end) {
        this.setState({
          openAlert: true,
          messageAlert: "The end date must not be less than the start date",
        });
      } else {
        const response = await getPaymentsCasesList(
          this.state.startDate,
          this.state.endDate
        );
        const responseProcessed = await getProcessedPayments(
          this.state.startDate,
          this.state.endDate
        );
        const ids = [];
        responseProcessed.forEach((element) => {
          ids.push(element.id);
        });
        response.forEach((a) => {
          //TODO: Add other forEach to evaluate the tickets no the group
          if (ids.includes(a.id)) {
            a.status = "Processed";
          } else {
            a.status = "Pending";
          }
        });
        const listWithProcessed = [];
        response.forEach((a) => {
          if (a.status === "Pending") listWithProcessed.push(a);
        });
        const ticketsProcessed = [];
        const tickets = [];
        listWithProcessed.forEach((element) => {
          element.tickets.forEach((a) => {
            a.first_name = element.first_name;
            a.last_name = element.last_name;
            a.cell_phone = element.cell_phone;
            a.state = element.state;
            a.paid = element.paid;
            a.status = element.status;
            a.license_number = element.license_number;
            a.address = element.address;
            a.county = element.county;
            a.dob = element.dob;
            a.email = element.email;
            a.id_case = element.id;
            ticketsProcessed.push(a);
          });
        });
        response.forEach((element) => {
          element.tickets.forEach((a) => {
            a.first_name = element.first_name;
            a.last_name = element.last_name;
            a.cell_phone = element.cell_phone;
            a.state = element.state;
            a.paid = element.paid;
            a.status = element.status;
            a.license_number = element.license_number;
            a.address = element.address;
            a.county = element.county;
            a.dob = element.dob;
            a.email = element.email;
            a.zip = element.zip;
            a.id_case = element.id;
            tickets.push(a);
          });
        });
        this.setState({
          list: ticketsProcessed,
          processedList: tickets,
          notProcessedList: ticketsProcessed,
        });
        const newData = this.state.list.map((item) => ({
          ...item,
          isChecked: false,
        }));
        this.setState({ list: newData });
        if (this.state.list.length === 0) this.setState({ showMessage: true });
      }
      this.setState({ backShow: false });
    } catch (error) {
      console.log(error);
    }
  }

  // checkRow(id) {
  //   //let tempList = this.state.list;
  //   // tempList.map((i) => {
  //   //   i.isChecked = false;
  //   //   if (i.id === id) {
  //   //     i.isChecked = !i.isChecked;
  //   //   }
  //   //   return i;
  //   // });
  //   const tempList = [];
  //   this.state.list.forEach((element) =>{
  //     element.isChecked = true;
  //     // if(element.id === id){
  //     //   element.isChecked = !element.isChecked;
  //     // }
  //     tempList.push(element);
  //   });
  //   this.setState({ list: tempList });
  // }

  openDetail(id) {
    let tempList = this.state.list;
    tempList.map((i) => {
      if (i.id === id) {
        i.isOpen = !i.isOpen;
      }
      return i;
    });
    this.setState({ list: tempList });
  }

  handleClose() {
    this.setState({ openAlert: false });
  }

  handleProcessPayments(id) {
    this.state.list.forEach((element) => {
      if (element.id === id) {
        localStorage.setItem("payment", JSON.stringify(element));
        this.props.history.push("/new-quote");
      }
    });
    // let tempList = this.state.list;
    // if (tempList.find((e) => e["isChecked"] === true)) {
    //   this.setState({ backShow: true });
    //   this.state.list.forEach((element) => {
    //     if (element.isChecked) addPaymentsProcessed(element);
    //   });
    //   this.setState({
    //     backShow: false,
    //     openAlert: true,
    //     messageAlert: "Payments processed successfully",
    //   });
    //   this.paymentsList()
    // } else {
    //   this.setState({
    //     openAlert: true,
    //     messageAlert: "At least check one case",
    //   });
    // }
  }

  handleShowProcessed() {
    if (this.state.showProcessed) {
      this.setState({ list: this.state.notProcessedList });
    } else {
      this.setState({ list: this.state.processedList });
    }
  }

  handleGoAnotherPage() {
    return <NewQuotePage />;
  }

  render() {
    return (
      <div>
        {this.state.showPage ? (
          <div
            style={{
              flexGrow: 1,
              paddingTop: 20,
              paddingLeft: 50,
              paddingRight: 50,
              paddingBottom: 50,
            }}
          >
            <Grid container spacing={3}>
              <Grid item md={3} lg={3} xs={12}>
                <TextField
                  id="date"
                  label="Start Date"
                  type="date"
                  variant="outlined"
                  style={{
                    width: "100%",
                    height: 30,
                    marginTop: 20,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.startDate}
                  onChange={(e) => this.setState({ startDate: e.target.value })}
                />
              </Grid>
              <Grid item md={3} lg={3} xs={12}>
                <TextField
                  id="date"
                  label="End Date"
                  type="date"
                  variant="outlined"
                  style={{
                    width: "100%",
                    height: 30,
                    marginTop: 20,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.endDate}
                  onChange={(e) => this.setState({ endDate: e.target.value })}
                />
              </Grid>
              <Grid item md={3} lg={3} xs={12}>
                <Grid item md={12} lg={12} xs={12}>
                  <Button
                    variant="contained"
                    style={{
                      marginTop: 20,
                      color: "#FFEF65",
                      height: 55,
                      backgroundColor: "black",
                      width: "50%",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                    onClick={() => this.paymentsList()}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item md={12} lg={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.showProcessed}
                        onChange={() => {
                          this.setState({
                            showProcessed: !this.state.showProcessed,
                          });
                          this.handleShowProcessed();
                        }}
                        name="processed"
                      />
                    }
                    label="Show processed cases"
                  />
                </Grid>
              </Grid>
              {this.state.list.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <TableContainer style={{ paddingTop: 20 }}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold" }}
                            >
                              #
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold" }}
                            >
                              Client
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold" }}
                            >
                              Mobile Number
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold" }}
                            >
                              State
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold" }}
                            >
                              Is Paid?
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold" }}
                            >
                              Citation Date
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold" }}
                            >
                              Citation Number
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold" }}
                            >
                              Status
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ fontWeight: "bold" }}
                            >
                              Citation Fee
                            </StyledTableCell>
                            <StyledTableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.list.map((row) => (
                            <>
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.id}</StyledTableCell>
                                <StyledTableCell>
                                  {row.first_name + " " + row.last_name}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.cell_phone}
                                </StyledTableCell>
                                <StyledTableCell>{row.state}</StyledTableCell>
                                <StyledTableCell>
                                  {row.paid === 1 ? "YES" : "NO"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.citations_date}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.citations_number}
                                </StyledTableCell>
                                <StyledTableCell>{row.status}</StyledTableCell>
                                <StyledTableCell>
                                  $ {row.citations_fee}
                                </StyledTableCell>
                                <StyledTableCell padding="checkbox">
                                  {row.status === "Pending" && (
                                    <Button
                                      variant="contained"
                                      style={{
                                        color: "#FFEF65",
                                        backgroundColor: "black",
                                        width: "80%",
                                        fontWeight: "bold",
                                        fontSize: 12,
                                      }}
                                      onClick={() =>
                                        this.handleProcessPayments(row.id)
                                      }
                                    >
                                      Process
                                    </Button>
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  {/* <Grid item md={3} lg={3} xs={12} style={{ align: "right" }}>
                  <Button
                    variant="contained"
                    style={{
                      marginTop: 20,
                      color: "#FFEF65",
                      height: 55,
                      backgroundColor: "black",
                      width: "100%",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                    onClick={this.handleProcessPayments}
                  >
                    PROCESS
                  </Button>
                </Grid> */}
                </>
              ) : (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  {this.state.showMessage && (
                    <h1>There is no match data with the search filter</h1>
                  )}
                </Grid>
              )}
            </Grid>
            <Dialog
              open={this.state.openAlert}
              onClose={this.handleClose}
              aria-labelledby="responsive-dialog-title"
              maxWidth={"xs"}
            >
              <DialogTitle
                id="responsive-dialog-title"
                style={{
                  backgroundColor: "#FFEF65",
                  color: "black",
                  textAlign: "center",
                }}
              >
                {this.state.messageAlert}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  style={{
                    color: "#FFEF65",
                    backgroundColor: "black",
                    height: 50,
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Backdrop
              style={{ position: "absolute", zIndex: 1 }}
              open={this.state.backShow}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <h1>Loading...</h1>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(QuoteListPage));
