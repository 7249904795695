import axios from "../utils/axios";

export const saveCitation = async (payload) => {
  try {
    const response = await axios.post(`/case/AddCase`, payload, {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCase = async (barcodeId) => {
  try {
    const response = await axios.post(`/case/DeleteCase`, barcodeId, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const validateCase = async (caseNumber) => {
  try {
    const response = await axios.get(`/case/ValidateCase/${caseNumber}`, {
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const validateCitation = async (citationNumber) => {
  try {
    const response = await axios.get(
      `/case/ValidateCitation/${citationNumber}`,
      {
        headers: {
          "Allow-Cross-Origin": "*",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
