import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Backdrop,
    CircularProgress
} from '@material-ui/core';
import { savePayment, generateUid } from '../api/payment';
import { BASE_URL } from '../utils/constants';
import IMAGES from '../utils/images';
import { withRouter } from "react-router";
import { getStates, getCityByZipcode } from '../api/location';
import countriesData from '../enums/countries.json';
import sexData from '../enums/sex.json';
import MuiPhoneNumber from 'material-ui-phone-number';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        paddingTop: 20,
        paddingLeft: 60,
        paddingRight: 60,
        /*maxWidth: 600,
        alignItems: 'center',
        alignContent: 'center',
        justify: 'center'*/
    },
    headerSection: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: 'black',
        fontWeight: 'bold',
        backgroundColor: '#FFEF65',
        borderRadius: 7
    },
    subHeaderSection: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: '#FFEF65',
        fontWeight: 'bold',
        backgroundColor: 'black'
    },
    payButton: {
        color: '#FFEF65',
        backgroundColor: 'black',
        height: 50,
        width: 150,
        fontWeight: 'bold',
        fontSize: 16,
        marginTop: 20
    },
    inputText: {
        width: '100%',
        height: 30,
        marginTop: 20,
        borderRadius: 10
    },
    cardText: {
        width: '100%',
        height: 30,
        borderRadius: 10
    },
    modal: {
        backgroundColor: '#FFEF65',
        color: 'black',
        textAlign: 'center',
        //width: 400
    },
    buttonSearch: {
        color: '#FFEF65',
        backgroundColor: 'black',
        height: 50,
        width: '100%',
        fontWeight: 'bold',
        fontSize: 16
    },
    caseHeader: {
        fontWeight: 'bold',
        textAlign: 'right',
        width:'40%',
        paddingRight:10,
        fontSize: 14
    },
    caseLabel: {
        textAlign: 'left',
        width:'50%',
        fontSize: 14
    },
    addressText: {
        width: '100%',
        height: 30,
        marginTop: 20
    },
    checkbox: {
        marginTop: 10
    },
    table: {
        minWidth: 600
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class PaymentPage extends React.Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    this.state = {
      groupId: params.get("groupid"),
      firstName: "",
      lastName: "",
      email: "",
      paymentAmount: "",
      isSubmitting: false,
      alertMessage: "",
      openAlert: false,
      openTermsAndConditions: false,
      visibleButton: true,
      cases: [],
      caseState: "",
      caseCounty: "",
      caseNumber: "",
      caseCitationDate: "",
      backShow: false,
      client: {
        id: 0,
        firstName: "",
        lastName: "",
        middleName: "",
        driverLicense: "",
        mobileNumber: "",
        email: "",
        zipCode: "",
        countryId: "226",
        address1: "",
        address2: "",
        city: "0",
        licenseStateId: "0",
        sexId: "2",
        dateOfBirth: "",
        stateAbbr: "0",
      },
      errorMap: {
        eFirstName: false,
        eLastName: false,
        eDriverLicense: false,
        eMobileNumber: false,
        eEmail: false,
        eZipCode: false,
        eCountryId: false,
        eAddress1: false,
        eCity: false,
        eLicenseStateId: false,
        eSexId: false,
        eDateOfBirth: false,
        eStateAbbr: false,
      },
      sexs: sexData.sex,
      countries: countriesData.countries,
      prices: [],
      states: [],
      cities: [],
      filterTickets: [],
      barcodeId: [],
      errorCCNumber: "",
      errorCCExp: "",
      errorCCVV: "",
      disableButton: false,
      paymentExp: "",
      ticketList: "",
      caseNumberList: "",
      frameUid: "",
      cardType: "",
      paymentToken: "",
      // secureId: "",
      // apiKey: "",
      userName: "",
      countyName: ""
    };

    this.setState = this.setState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.finishSubmit = this.finishSubmit.bind(this);
    this.filterTicketList = this.filterTicketList.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseTermsAndConditions =
      this.handleCloseTermsAndConditions.bind(this);
    this.openTerms = this.openTerms.bind(this);
    this.getCityByZipcodeDropdow = this.getCityByZipcodeDropdow.bind(this);
    this.getStatesDropdown = this.getStatesDropdown.bind(this);
    this.getIframUid = this.getIframUid.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }

  componentDidMount() {
    this.getStatesDropdown();

    window.addEventListener("message", this.handleMessage, false);

    fetch(BASE_URL + "/case/GetPayInfoByQuoteGroupId/" + this.state.groupId, {
      method: "GET",
      headers: {
        "Allow-Cross-Origin": "*",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TTC_APIM_KEY,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        var citationDateFormat;
        let newPrices = [];
        let newCases = [];
        let barcodes = [];
        if (data.caseList.length > 0) {
          data.caseList.forEach((caseItem) => {
            if (caseItem.caseTickets.length > 0) {
              caseItem.caseTickets.forEach((element) => {
                let price = {};
                price.barcodeId = caseItem.barcodeId;
                price.ticketNumber = element.ticketNumber;
                price.chargeAmount = parseFloat(element.chargeAmount).toFixed(
                  2
                );
                price.infraction = element.infraction;
                newPrices.push(price);
              });
            }
            var date = new Date(caseItem.citationDate);
            citationDateFormat =
              date.getDate() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getFullYear();
            let newCase = {};
            newCase.barcodeId = caseItem.barcodeId;
            newCase.citationDate = citationDateFormat;
            newCase.caseNumber = caseItem.caseNumber;
            newCase.state = caseItem.state;
            newCase.county = caseItem.county;
            newCase.price = caseItem.payAmount.toFixed(2);
            newCase.isAlreadyPaid = caseItem.isAlreadyPaid;
            newCases.push(newCase);
          });
        }

        if (newCases.length > 0) {
          newCases.forEach((cItem) => {
            if (cItem.isAlreadyPaid != false) {
              this.setState({
                openAlert: true,
                alertMessage: "This Ticket has already been paid.",
                visibleButton: true,
                paymentExp: "PAI",
              });
            }
            barcodes.push(String(cItem.barcodeId));
          });
        }

        this.setState({
          firstName: data.client.firstName,
          lastName: data.client.lastName,
          email: data.client.email,
          cases: newCases,
          paymentAmount: parseFloat(data.payAmount).toFixed(2),
          client: {
            id: data.client.id,
            firstName: data.client.firstName,
            lastName: data.client.lastName,
            middleName: data.client.middleName,
            driverLicense: data.client.driverLicense,
            mobileNumber: data.client.mobileNumber,
            email: data.client.email,
            zipCode: data.client.zipCode,
            isActive: 1,
            countryId: data.client.country,
            address1: data.client.address1,
            address2: data.client.address2,
            city: data.client.city,
            licenseStateId: data.client.stateAbbr,
            stateAbbr: data.client.clientStateAbbr,
            sexId: data.client.sex,
            dateOfBirth: data.client.dateOfBirth,
          },
          prices: newPrices,
          ticketList: data.ticketList,
          barcodeId: barcodes,
          caseNumberList: data.caseNumberList,
          userName: data.userName,
          countyName: data.countyName
        });

        this.getCityByZipcodeDropdow(data.client.zipCode);
        this.getIframUid(parseFloat(data.payAmount).toFixed(2), data.client.firstName, data.client.lastName);

        if (data.paymentExpired === "YES") {
          this.setState({
            openAlert: true,
            alertMessage: "This payment link has already expired.",
            visibleButton: false,
            paymentExp: data.paymentExpired,
          });
        }
      });
  }

  handleMessage (evt) {
    console.log(evt);
    if (evt.data.subject == "completePayment"){
      if (evt.data.content.status == "Success") {
        this.setState({
          paymentToken: evt.data.content.txn_guid,
          cardType: evt.data.content.card_brand
        })
        this.handleSubmit();
      } else {
        this.setState({
          openAlert: true,
          alertMessage: "An error has occurred. Please refresh the page."
        });
        return false;
      }
    }
    if (evt.data.subject == "handlePaymentErrors"){
      console.error("An error has occurred. Please try again.")
      this.setState({
        openAlert: true,
        alertMessage: "An error has occurred. Please try again."
      });
      return false;
    }
  }

  async getIframUid(totalAmount, firstName, lastName) {
    const formData = {
      userName: this.state.userName,
      countyName: this.state.countyName,
      txn_type: "authCapture",
      mit_msg_type: "CREC",
      total_amount: totalAmount.toString(),
      order_id: this.state.groupId,
      billing_name: firstName + " " + lastName,
      form_name: "default",
      tokenize: "1"
    };

    const response = await generateUid(formData);

    if (typeof response != "undefined") {
      this.setState({
        frameUid: response,
      });
    } else {
      this.setState({
        openAlert: true,
        alertMessage:
          "A problem occurs while generating the payment link. Please try again later.",
        backShow: false,
      });
    }
  }

  async getCityByZipcodeDropdow(zipcode) {
    try {
      const response = await getCityByZipcode(zipcode);
      if (typeof response != "undefined") {
        this.setState({ cities: response });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getStatesDropdown() {
    try {
      const response = await getStates();
      if (typeof response != "undefined") {
        this.setState({ states: response });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async savePaymentCallback(formData) {
    try {
      this.setState({ backShow: true });
      const response = await savePayment(formData);
      if (typeof response != "undefined") {
        if (response != "Success") {
          this.setState({
            openAlert: true,
            alertMessage: response,
            visibleButton: true,
            backShow: false,
            paymentExp: "PAI",
          });
        } else {
          this.setState({
            openAlert: true,
            alertMessage: "Payment saved successfully",
            backShow: false,
            visibleButton: false,
            paymentExp: "NO",
          });
        }
      } else {
        this.setState({
          openAlert: true,
          alertMessage:
            "Your payment method has been rejected. Please try again later.",
          backShow: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  filterTicketList(barcodeId) {
    this.state.filterTickets.length = 0;
    let newFilter = this.state.filterTickets;
    for (let i = 0; i < this.state.prices.length; i++) {
      if (this.state.prices[i].barcodeId === barcodeId) {
        newFilter.push(this.state.prices[i]);
      }
    }
    this.setState({ filterTickets: newFilter });
  }

  validateFields(field, status, message) {
    switch (field) {
      case "ccnumber":
        if (!status)
          this.setState({
            errorCCNumber: message,
            isSubmitting: false,
            backShow: false,
          });
        else this.setState({ errorCCNumber: "" });
        break;
      case "ccexp":
        if (!status)
          this.setState({
            errorCCExp: message,
            isSubmitting: false,
            backShow: false,
          });
        else this.setState({ errorCCExp: "" });
        break;
      case "cvv":
        if (!status)
          this.setState({
            errorCCVV: message,
            isSubmitting: false,
            backShow: false,
          });
        else this.setState({ errorCCVV: "" });
        break;
    }
    if (
      this.state.errorCCNumber === "" &&
      this.state.errorCCExp === "" &&
      this.state.errorCCVV === ""
    ) {
      this.setState({ disableButton: false });
    }
  }

  finishSubmit() {
    const {
      isSubmitting,
      alertMessage,
      openAlert,
      openTermsAndConditions,
      visibleButton,
      paymentAmount,
      clientFirstName,
      clientLastName,
      clientMobileNumber,
      clientEmail,
      cases,
      sexs,
      states,
      cities,
      countries,
      prices,
      backShow,
      errorMap,
      ticketList,
      caseNumberList,
      errorTerms,
      errorCCNumber,
      errorCCExp,
      filterTickets,
      errorCCVV,
      disableButton,
      paymentExp,
      ...formData
    } = { ...this.state };
    formData.id = this.state.groupId;
    formData.quoteGroupId = this.state.groupId;
    formData.client.licenseStateId = parseInt(this.state.client.licenseStateId);
    formData.client.countryId = String(this.state.client.countryId);
    formData.paymentToken = this.state.paymentToken;
    formData.paymentAmount = parseFloat(this.state.paymentAmount);
    formData.ccName = this.state.cardType;
    this.savePaymentCallback(formData);
  }

  validateFormFields() {
    if (
      this.state.client.firstName === "" ||
      this.state.client.firstName === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eFirstName: true } });
    }
    if (
      this.state.client.lastName === "" ||
      this.state.client.lastName === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eLastName: true } });
    }
    if (
      this.state.client.driverLicense === "" ||
      this.state.client.driverLicense === null
    ) {
      this.setState({
        errorMap: { ...this.state.errorMap, eDriverLicense: true },
      });
    }
    if (
      this.state.client.mobileNumber.length > 5 ||
      this.state.client.mobileNumber === null
    ) {
      this.setState({
        errorMap: { ...this.state.errorMap, eMobileNumber: true },
      });
    }
    if (this.state.client.email === "" || this.state.client.email === null) {
      this.setState({ errorMap: { ...this.state.errorMap, eEmail: true } });
    }
    if (
      this.state.client.zipcode === "" ||
      this.state.client.zipcode === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eZipCode: true } });
    }
    if (
      this.state.client.countryId === "0" ||
      this.state.client.countryId === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eCountryId: true } });
    }
    if (
      this.state.client.address1 === "" ||
      this.state.client.address1 === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eAddress1: true } });
    }
    if (this.state.client.city === "0" || this.state.client.city === null) {
      this.setState({ errorMap: { ...this.state.errorMap, eCity: true } });
    }
    if (
      this.state.client.licenseStateId === "0" ||
      this.state.client.licenseStateId === null
    ) {
      this.setState({
        errorMap: { ...this.state.errorMap, eLicenseStateId: true },
      });
    }
    if (this.state.client.sexId === "0" || this.state.client.sexId === null) {
      this.setState({ errorMap: { ...this.state.errorMap, eSexId: true } });
    }
    if (
      this.state.client.dateOfBirth === "" ||
      this.state.client.dateOfBirth === null
    ) {
      this.setState({
        errorMap: { ...this.state.errorMap, eDateOfBirth: true },
      });
    }
    if (
      this.state.client.stateAbbr === "0" ||
      this.state.client.stateAbbr === null
    ) {
      this.setState({ errorMap: { ...this.state.errorMap, eStateAbbr: true } });
    }
    if (Object.values(this.state.errorMap).includes(true)) {
      this.setState({
        openAlert: true,
        alertMessage: "One or more fields are required",
        visibleButton: true,
      });
      return false;
    } else {
      return true;
    }
  }

  handleSubmit() {
    if (this.state.client.mobileNumber.length > 5) {
      this.setState({
        errorMap: { ...this.state.errorMap, eMobileNumber: false },
        isSubmitting: true,
      });
      this.finishSubmit();
    } else {
      this.setState({
        errorMap: { ...this.state.errorMap, eMobileNumber: true },
      });
      this.setState({
        openAlert: true,
        alertMessage: "Mobile number is required",
        visibleButton: true,
      });
      return false;
    }
  }

  handleClose() {
    this.setState({ openAlert: false });
    if (
      this.state.visibleButton === false ||
      this.state.alertMessage === "This Ticket has already been paid."
    ) {
      this.props.history.push(
        "/payment-success?exp=" +
          this.state.paymentExp +
          "&emi=" +
          this.state.client.email
      );
    }
  }

  handleCloseTermsAndConditions() {
    this.setState({ openTermsAndConditions: false });
  }

  openTerms() {
    this.setState({ openTermsAndConditions: true });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.subHeaderSection}>
          <table style={{ width: "100%", textAlign: "center" }}>
            <tr>
              <td style={{ textAlign: "center", width: "30%" }}>
                <img alt="path" src={IMAGES.TTCLogo} width={70} height={70} />
              </td>
              <td style={{ textAlign: "left", width: "70%" }}>
                Case / Citation: {this.state.caseNumberList} /{" "}
                {this.state.ticketList}
              </td>
            </tr>
          </table>
        </div>
        <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.headerSection}>Client Details</div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <b>
                  <i>
                    Please review and correct your personal information if
                    necessary.
                  </i>
                </b>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  disabled="true"
                  label="First Name"
                  className={classes.inputText}
                  variant="outlined"
                  required
                  error={this.state.errorMap.eFirstName}
                  value={this.state.client.firstName}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        firstName: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  disabled="true"
                  label="Last Name"
                  className={classes.inputText}
                  variant="outlined"
                  required
                  value={this.state.client.lastName}
                  error={this.state.errorMap.eLastName}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        lastName: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  disabled="true"
                  label="Middle Name"
                  className={classes.inputText}
                  variant="outlined"
                  value={this.state.client.middleName}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        middleName: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  label="Email"
                  className={classes.inputText}
                  variant="outlined"
                  required
                  error={this.state.errorMap.eEmail}
                  value={this.state.client.email}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        email: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label="Address 1"
                  className={classes.addressText}
                  variant="outlined"
                  required
                  error={this.state.errorMap.eAddress1}
                  value={this.state.client.address1}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        address1: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label="Address 2"
                  className={classes.addressText}
                  variant="outlined"
                  value={this.state.client.address2}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        address2: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  label="Zipcode"
                  className={classes.inputText}
                  variant="outlined"
                  required
                  value={this.state.client.zipCode}
                  error={this.state.errorMap.eZipCode}
                  onChange={(event) => {
                    this.setState({
                      client: {
                        ...this.state.client,
                        zipCode: event.target.value,
                      },
                    });
                    this.getCityByZipcodeDropdow(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  select
                  label="City"
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  required
                  value={this.state.client.city}
                  error={this.state.errorMap.eCity}
                  className={classes.inputText}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        city: event.target.value,
                      },
                    })
                  }
                >
                  <option key="0" disabled value="0">
                    --Select--
                  </option>
                  {this.state.cities.map((option) => (
                    <option key={option.cityName} value={option.cityName}>
                      {option.cityName}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  select
                  label="Country"
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  className={classes.inputText}
                  value={this.state.client.countryId}
                  required
                  error={this.state.errorMap.eCountryId}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        countryId: event.target.value,
                      },
                    })
                  }
                >
                  <option key="0" disabled value="0">
                    --Select--
                  </option>
                  {this.state.countries.map((option) => (
                    <option key={option.countryId} value={option.countryId}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  select
                  label="State"
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  className={classes.inputText}
                  required
                  error={this.state.errorMap.eStateAbbr}
                  value={this.state.client.stateAbbr}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        stateAbbr: event.target.value,
                      },
                    })
                  }
                >
                  <option key="0" value="0" disabled>
                    --Select--
                  </option>
                  {this.state.states.map((option) => (
                    <option key={option.id} value={option.stateAbbr}>
                      {option.stateName}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <MuiPhoneNumber
                  defaultCountry={"us"}
                  value={this.state.client.mobileNumber}
                  variant="outlined"
                  label="Mobile Number"
                  required={this.state.errorMap.eMobileNumber}
                  error={this.state.errorMap.eMobileNumber}
                  className={classes.inputText}
                  onChange={(event) =>
                    this.setState({
                      client: { ...this.state.client, mobileNumber: event },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  select
                  label="Sex"
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  value={this.state.client.sexId}
                  required
                  error={this.state.errorMap.eSexId}
                  className={classes.inputText}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        sexId: event.target.value,
                      },
                    })
                  }
                >
                  <option key="0" value="0" disabled>
                    --Select--
                  </option>
                  {this.state.sexs.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  id="date"
                  label="Date Of Birth"
                  type="date"
                  variant="outlined"
                  className={classes.inputText}
                  required
                  error={this.state.errorMap.eDateOfBirth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.client.dateOfBirth}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        dateOfBirth: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  label="Driver License"
                  className={classes.inputText}
                  variant="outlined"
                  required
                  error={this.state.errorMap.eDriverLicense}
                  value={this.state.client.driverLicense}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        driverLicense: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  select
                  label="State Issued"
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  required
                  error={this.state.errorMap.eLicenseStateId}
                  value={this.state.client.licenseStateId}
                  className={classes.inputText}
                  onChange={(event) =>
                    this.setState({
                      client: {
                        ...this.state.client,
                        licenseStateId: event.target.value,
                      },
                    })
                  }
                >
                  <option key="0" disabled value="0">
                    --Select--
                  </option>
                  {this.state.states.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.stateAbbr}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <div style={{ paddingTop: 25 }} />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.headerSection}>Case List</div>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          State
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          County
                        </TableCell>
                        <TableCell
                          algin="center"
                          style={{ fontWeight: "bold" }}
                        >
                          Case Number
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          Citation Date
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          Tickets
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.cases.map((row) => (
                        <TableRow key={row.caseNumber}>
                          <TableCell component="th" align="center" scope="row">
                            {row.state}
                          </TableCell>
                          <TableCell align="center">{row.county}</TableCell>
                          <TableCell align="center">{row.caseNumber}</TableCell>
                          <TableCell align="center">
                            {row.citationDate}
                          </TableCell>
                          <TableCell align="center">$ {row.price}</TableCell>
                          <TableCell align="center">
                            <label
                              onClick={() =>
                                this.filterTicketList(row.barcodeId)
                              }
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              View
                            </label>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td className={classes.caseHeader}>State:</td>
                                        <td className={classes.caseLabel}>{this.state.caseState}</td>
                                    </tr>
                                </table>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td className={classes.caseHeader}>County:</td>
                                        <td className={classes.caseLabel}>{this.state.caseCounty}</td>
                                    </tr>
                                </table>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td className={classes.caseHeader}>Case Number:</td>
                                        <td className={classes.caseLabel}>{this.state.caseNumber}</td>
                                    </tr>
                                </table>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td className={classes.caseHeader}>Citation Date:</td>
                                        <td className={classes.caseLabel}>{this.state.caseCitationDate}</td>
                                    </tr>
                                </table>
                            </Grid> */}
              <Grid item xs={12}>
                <div className={classes.headerSection}>Case Tickets</div>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={{ fontWeight: "bold" }}>
                          Citation Number
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          Description
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>
                          Price
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.filterTickets.map((row) => (
                        <TableRow key={row.ticketNumber}>
                          <TableCell component="th" scope="row">
                            {row.ticketNumber}
                          </TableCell>
                          <TableCell align="center">{row.infraction}</TableCell>
                          <TableCell align="right">
                            $ {row.chargeAmount}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.headerSection}>Payment Information</div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ textAlign: "center", display: "flex", flexFlow: "row", justifyContent: "center" }}
              >
                <div style={{ width: "50%" }}>
                    <img src={IMAGES.CreditCards} alt="path" width="100%" height="100%"></img>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label="Amount"
                  className={classes.inputText}
                  variant="outlined"
                  disabled
                  onChange={(event) =>
                    this.setState({ paymentAmount: event.target.value })
                  }
                  value={this.state.paymentAmount}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 30, paddingLeft: 5 }}>
                <iframe id="hpfFrame" title="paymentFrame" src={process.env.REACT_APP_TTC_IFRAME_URL + this.state.frameUid}
                style={{ width: "100%", overflow: "hidden", minHeight: "400px", border: "0" }}
                >
                </iframe>
              </Grid>
            </Grid>
          <Dialog
            open={this.state.openAlert}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"xs"}
          >
            <DialogTitle id="responsive-dialog-title" className={classes.modal}>
              {this.state.alertMessage}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                className={classes.buttonSearch}
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openTermsAndConditions}
            onClose={this.handleCloseTermsAndConditions}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"md"}
          >
            <DialogTitle
              id="responsive-dialog-title"
              className={classes.modal}
              style={{ fontWeight: "bold" }}
            >
              TERMS &#38; CONDITIONS
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: "black" }}>
                <p style={{ textAlign: "justify" }}>
                  By submitting payment through the “PAY NOW” option, the
                  Client, retains and employs THE TICKET CLINIC, A LAW FIRM, to
                  represent him/her in his/her case submitted on this
                  application. The fee paid is non-refundable, and excludes
                  fines and court costs, if any, which can exceed the price of
                  the ticket, for which the client shall remain solely liable.
                  Client understands that this fee is earned immediately, is
                  non-refundable and is not based on an hourly fee. This fee is
                  exclusive of any appeals. There will be no refunds so please
                  be certain that you wish to hire us. No representation has
                  been made to the client concerning the probability of success
                  as to their case. The client also acknowledges and understands
                  that he/she retained THE TICKET CLINIC and no representations
                  have been made regarding which attorney at the firm shall
                  represent him/her. Further, THE TICKET CLINIC may substitute
                  another attorney not associated with the firm to represent the
                  client.
                </p>
                <ol>
                  <li value="1" style={{ textAlign: "justify" }}>
                    The client authorizes, THE TICKET CLINIC to represent
                    him/her in this matter, and to either maintain a not guilty
                    plea or enter a no contest plea on the client’s behalf at
                    THE TICKET CLINIC’s discretion.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    In some counties, attorney appearances are permitted by mail
                    or by phone. Client understands that if this situation
                    arises, our attorney may choose to exercise that option.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    The client understands that should his/her attorney be in
                    another courtroom at the time of their trial and the client
                    chooses to be present, client is to wait for his/her
                    attorney. If client decides to handle the case on their own
                    he/she will NOT receive a refund.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                  We’re a law firm that specializes in traffic law, so if you were to hire one of our attorneys, he’ll represent you in court & make all the appearances on your behalf.  The primary goal would be to get your case dismissed at trial so that way you don’t have to pay any fines to the court or incur any points on your license & also no traffic school will be required.  We cannot guarantee a dismissal since it’s against the law for a law firm to guarantee any sort of outcome.  However, if your ticket is not dismissed, then the attorney will attempt to negotiate with the court to get your ticket reduced to something that carries no points and possibly get your fines reduced also.  We do have over 25 years of experience and an 80% success rate. By submitting payment through the “PAY NOW” option, the Client, retains and employs THE TICKET CLINIC, A LAW FIRM, to represent him/her in his/her case submitted on this application. The fee paid is non-refundable, and excludes fines and court costs, if any, which can exceed the price of the ticket, for which the client shall remain solely liable. Client understands that this fee is earned immediately, is non-refundable and is not based on an hourly fee. This fee is exclusive of any appeals. There will be no refunds so please be certain that you wish to hire us. No representation has been made to the client concerning the probability of success as to their case. The client also acknowledges and understands that he/she retained THE TICKET CLINIC and no representations have been made regarding which attorney at the firm shall represent him/her. Further, THE TICKET CLINIC may substitute another attorney not associated with the firm to represent the client.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    Some counties allow our law firm to appear via a written
                    plea. When scheduling conflicts arise, or our attorney is
                    unable to attend the scheduled hearing, this option is used
                    sparingly. In those cases, our firm makes our best attempt
                    to obtain a favorable outcome for the client without an
                    attorney from the firm appearing in person.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    The client understands that the money back guarantee
                    provided by THE TICKET CLINIC is as follows: Alachua, Baker,
                    Bay, Bradford, Calhoun, Citrus, Clay, Escambia, Franklin,
                    Gadsden, Gilchrist, Glades, Gulf, Hamilton, Hardee, Hendry,
                    Hernando, Highlands, Holmes, Jackson, Jefferson, Lafayette,
                    Leon, Liberty, Madison, Okaloosa, Putnam, Santa Rosa,
                    Sarasota, Suwannee, Taylor, Union, Walton, Washington
                    County: No points if no tickets in past 2 years (excludes
                    school/construction zone, accident cases, &#38; mandatory
                    court cases) no traffic school if no tickets in past 2 years
                    and not exceeding 19 over the limit (excludes
                    school/construction zone, accident cases &#38; mandatory
                    court cases). Manatee: No points if no tickets in past 2
                    years (excludes school/construction zone, accident cases
                    with injuries, &#38; mandatory court cases) no traffic
                    school if no tickets in past 2 years and not exceeding 19
                    over the limit (excludes school/construction zone, accident
                    cases with injuries &#38; mandatory court cases). Volusia
                    &#38; Flagler: We guarantee no points regardless of driving
                    history. (Excludes school/construction zones, accident with
                    injuries &#38; mandatory court case. No traffic school if no
                    tickets in the past 2 years &#38; was not exceeding 29 mph
                    over the posted speed limit. (Excludes school/construction
                    zones, accident w/ injuries and mandatory court cases. Duval
                    and Nassau Counties: We guarantee no points if not tickets
                    in the past 12 months (excludes school zone, construction
                    zones, accident cases and mandatory court cases), no
                    guarantee for driving school. St Johns County: We guarantee
                    no points if no tickets in the past 12 months (excludes
                    school and construction zones, accident cases and mandatory
                    court cases) and no school if not tickets in the past 2
                    years and not exceeding 19 mph over (excludes school and
                    construction zones, accident cases with injuries and
                    mandatory court cases). Indian River, Martin, Okeechobee,
                    &#38; St. Lucie: No points if no tickets in the past 2
                    years. (Excludes accident with injury cases &#38; mandatory
                    court cases), no driving school if no tickets in the past 2
                    years (excluding school/construction zones, accidents with
                    injuries and mandatory court cases). Palm Beach &#38; Lee
                    County: No points if not tickets in the past 2 years
                    (Excluding accidents with injuries and mandatory court cases
                    ONLY) No driving school if no tickets in the past 3 years
                    and was not exceeding 14 mph over the speed limit. Excluding
                    school an construction zones, accidents with injuries and
                    mandatory court dates. Monroe County: No points and no
                    school if no tickets in the past 2 years (excludes all
                    accident cases, school/construction zone cases, passing a
                    school bus and mandatory cases). Collier County: No points
                    if no tickets in the past 2 years and not exceeding 20 mph
                    over the speed limit (excludes school/construction zones,
                    accident cases &#38; mandatory court cases), no driving
                    school if no tickets in the past 3 years and was not
                    exceeding 14 mph over the speed limit (excluding
                    school/construction zones, accidents with injuries and
                    mandatory court cases) Columbia, Levy, &#38; Wakulla,
                    County: No points if no tickets in the past 5 years, not
                    exceeding 14 mph over the limit (excludes
                    school/construction, accident cases &#38; mandatory court
                    cases), traffic school is not guaranteed. Brevard, Lake
                    &#38; Orange: no points guaranteed regardless of the driving
                    history, (excludes mandatory court cases,
                    school/construction zone cases &#38; accident w/injuries
                    cases), no driving school if no tickets in the past 3 yrs.
                    not exceeding 14 mph over the limit (excludes
                    school/construction zone, accident w/injuries cases &#38;
                    mandatory court cases). Seminole County: no points if no
                    tickets in the past 12 months (excludes mandatory court
                    cases, school/construction zone cases &#38; accident w/
                    injuries cases) &#38; no driving school if no tickets in the
                    past 2 years (excludes school/construction zone, accident
                    w/injuries cases, &#38; mandatory court cases). Osceola
                    County: no points guaranteed (excludes mandatory court
                    cases, school/construction zone cases &#38; accident cases),
                    no traffic school so long as no other tickets in the past 2
                    years, not exceeding 14 mph over the speed limit (traffic
                    school guarantee excludes mandatory court cases,
                    school/construction zone cases, accident cases and move-over
                    law violations).Dixie &#38; Polk County: No points if no
                    tickets in the past 2 years (excludes school/construction
                    zone, accident cases &#38; mandatory court cases), traffic
                    school is not guaranteed. Broward, Miami- Dade County: no
                    points &#38; no driving school if no tickets in the past 2
                    years, (excludes school/construction zone, violation of
                    traffic control device citations, mandatory court cases
                    &#38; accident with injury cases), client must have valid
                    license for 2+ years to qualify. Charlotte, Desoto, &#38;
                    Marion County: guaranteed no points if no tickets in the
                    past 3 years (excludes school/construction zone, accident
                    cases with injuries, &#38; mandatory court cases), no
                    guarantee for driving school. Sumter County: guaranteed no
                    points if no tickets in the past 3 years and not exceeding
                    19 mph over the posted speed limit (excludes
                    school/construction zone, accident cases, &#38; mandatory
                    court cases), no guarantee for driving school. Pasco: no
                    points if no tickets in the past 3 years (excludes
                    school/construction, accident with injuries cases &#38;
                    mandatory court cases), no traffic school if no tickets in
                    the past 5 years and not exceeding 19 mph over the posted
                    speed limit (excludes school/construction zone, accident w/
                    injuries cases, &#38; mandatory court cases) Hillsborough
                    &#38; Pinellas Counties : no points if no tickets in the
                    past 2 years (excludes accidents cases, construction/school
                    zone &#38; mandatory court cases), no traffic school if no
                    tickets in the past 2 years, and no more than 5 other
                    tickets on lifetime driving record (excludes accidents
                    cases, construction/school zone &#38; mandatory court
                    cases).
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    All money-back guarantees are based on actual speed, NOT
                    necessarily the speed referenced on the citation. This may
                    affect the amount ordered for the fine and/or court costs,
                    as the fine/cost imposed may relate to the actual speed. If
                    your ticket is for 30 mph or more over the speed limit, or
                    for passing a stopped school bus, Florida law does not allow
                    for you to pay for your ticket or elect driving school.
                    There is a mandatory court appearance required either by the
                    client or an attorney (this is considered a “mandatory court
                    case”). The client is aware that THE TICKET CLINIC offers no
                    money-back guarantee for these types of cases and the
                    driver’s license may be suspended by the Court.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    For drivers licensed outside the State of Florida, while we
                    do offer a money-back guarantee for many Florida citations
                    (restrictions apply, call for details) we do not guarantee
                    or promise how the outcome that we obtain for you in Florida
                    will affect your home-state driving record. If this is of
                    concern to you, you should contact your home-state DMV so
                    that your questions may be answered by them.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    Fees are based on the representations of the Client in the
                    ‘hire us now” form. Should the actual charges, dates,
                    counties, or other information differ from said
                    representations, the TICKET CLINIC may, at its option,
                    withdraw from the case, charge an additional fee, or both.
                    In either case, there will be no refund.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    You agree that all future communication from The Ticket
                    Clinic to you may be paperless, either email or SMS. It is
                    your responsibility to notify The Ticket Clinic if your
                    email, phone number , or physical address changes.
                  </li>
                </ol>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseTermsAndConditions}
                className={classes.buttonSearch}
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Backdrop className={classes.backdrop} open={this.state.backShow}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>
    );
  }
};

export default withRouter(withStyles(useStyles)(PaymentPage));